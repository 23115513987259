import pickBy from 'lodash/pickBy';
import includes from 'lodash/includes';

import { Duration } from 'ts-luxon';
import confetti from 'canvas-confetti';

import { CONFETTI_COLORS } from '../../constants';
import { CourseAnswers } from '../../pages/learn/[id]';

export { default as Mixpanel } from './mixpanel';
export { default as GA } from './ga';
export { default as getAuthErrorMessage } from './getAuthErrorMessage';

export const validateCourseAnswers = async (value?: string) => {
  return value;
};

export const validatePassedCourseThreshold = async (value?: string) => {
  return value;
};

export const validateAnswers = async (answers: CourseAnswers) => {
  return fetch('/api/validate-course-answers.handler', {
    method: 'POST',
    body: JSON.stringify({
      moduleIds: Object.keys(answers),
      answers,
    }),
  })
    .then((data) => data.json())
    .then((data) => {
      return data;
    });
};

export const validateCoursePassed = async (dispatch: Function, courseId?: string, answersValidated?: CourseAnswers) => {
  return fetch('/api/validate-course-passed-threshold.handler', {
    method: 'POST',
    body: JSON.stringify({
      courseId,
      answersValidated,
    }),
  })
    .then((data) => data.json())
    .then((data) => {
      dispatch({ type: 'completed' });
      dispatch({ type: 'passed', payload: data });
      return data;
    });
};

export const calculateAmount = (percentageDiscount: number, totalInGBP: number, promoCode: string) => {
  const discountPercent = percentageDiscount && promoCode ? percentageDiscount : 0;
  const discountPercentage = Number(discountPercent);
  const discount = totalInGBP * (discountPercentage / 100);

  return (totalInGBP - discount) * 100; // times by 100 as the units of the API are in pence
};

export const optionKeys = (objectToCheck: object, str: string) =>
  Object.values(pickBy(objectToCheck, (_, key) => includes(key, str)));

export const acronym = (text: string) => {
  return text.split(/\s/).reduce(function (accumulator, word) {
    return accumulator + word.charAt(0);
  }, '');
};

export const convertMinsToHrsMins = (mins: number) => {
  const h = Math.floor(mins / 60);
  const m = mins % 60;

  return `${h}h ${m}m`;
};

export const formatSeconds = (sec: number) => {
  const milliseconds = sec * 1000;
  const time = Duration.fromMillis(milliseconds).shiftTo('hours', 'minutes', 'seconds').toObject();
  const { hours, minutes, seconds } = time;

  const displayedSeconds = seconds && seconds <= 9 ? `0${seconds}` : seconds;
  const displayedMinutes = minutes && minutes <= 9 ? `0${minutes}` : minutes;
  const displayedHours = hours && hours <= 9 ? `0${hours}` : hours;

  return hours
    ? `${displayedHours}:${displayedMinutes}:${displayedSeconds}`
    : `${displayedMinutes}:${displayedSeconds}`;
};

export const isValidUrl = (string: string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const releaseConfetti = () => {
  const duration = 4000;
  const animationEnd = Date.now() + duration;
  let skew = 1;

  const randomInRange = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
  };

  const frame = () => {
    const timeLeft = animationEnd - Date.now();
    const ticks = Math.max(500 * (timeLeft / duration));
    skew = Math.max(0.8, skew - 0.001);

    confetti({
      particleCount: 1,
      startVelocity: 0,
      ticks: ticks,
      origin: {
        x: Math.random(),
        // since particles fall down, skew start toward the top
        y: Math.random() * skew - 0.2,
      },
      colors: [CONFETTI_COLORS[Math.floor(Math.random() * (CONFETTI_COLORS.length + 1))]],
      gravity: randomInRange(0.4, 0.6),
      scalar: randomInRange(0.5, 3),
      drift: randomInRange(-0.2, 0.2),
    });

    if (timeLeft > 0) {
      requestAnimationFrame(frame);
    }
  };

  frame();
};

export const getLinkFromString = (string: string) => {
  if (string.substring(0, 8).includes('https://') || string.substring(0, 7).includes('http://')) {
    return string;
  }
  return 'https://' + string;
};

export const readingTime = (text: string) => {
  const wpm = 275;
  const words = text.trim().split(/\s+/).length;

  /*
    Reading speeds (wpm)

    6th-8th Grade (Spring) 11, 12, 13, 14 years old	150 – 204 wpm
    Highschool 14, 15, 16, 17, 18 years old	200 – 300 wpm
    College 18-23 years old	300 – 350 wpm
    Adults	220 – 350 wpm

  **/

  return Math.ceil(words / wpm);
};

export const reviewAnswersCount = () => {
  return true;
};

export const shuffleArray = (array: Array<string>) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};
