import mixpanel from 'mixpanel-browser';
import pickBy from 'lodash/pickBy';

import { isInternalDomain } from './hideForExternalUsers';

mixpanel.init(process.env.MIXPANEL_PROJECT_TOKEN || '');

const canTrack = process.env.BUILD_ENV === 'prod';

type PropsType =
  | {
      moduleId?: string;
      firstName?: string;
      email?: string;
      lastName?: string;
      dateOfBirth?: string;
      message?: string;
    }
  | undefined;

const removeNullProperties = (props: PropsType) => pickBy(props);

export const Mixpanel = {
  formatAmountForTracking: async (amount: number) => {
    const formattedAmount = new Intl.NumberFormat('en-GB', {
      style: 'decimal',
    }).format(amount / 100);

    return Number(formattedAmount);
  },

  identify: async (email: string) => {
    if (canTrack && !isInternalDomain(email)) await mixpanel.identify(email);
  },

  alias: async (id: string, email: string) => {
    if (canTrack && !isInternalDomain(email)) await mixpanel.alias(id);
  },

  track: async (name: string, email: string, props?: PropsType) => {
    if (canTrack && !isInternalDomain(email)) await mixpanel.track(name, removeNullProperties(props));
  },

  reset: async () => {
    if (canTrack) await mixpanel.reset();
  },

  people: {
    trackCharge: async (amount: number, currency: string, email: string) => {
      if (canTrack && !isInternalDomain(email)) {
        await mixpanel.people.track_charge(amount, {
          original_currency: currency,
        });
      }
    },

    increment: async (property: string, value: number, email: string) => {
      if (canTrack && !isInternalDomain(email)) {
        await mixpanel.people.increment(property, value);
      }
    },

    set: async (props: PropsType, email: string) => {
      if (canTrack && !isInternalDomain(email)) {
        await mixpanel.people?.set(removeNullProperties(props));
      }
    },

    delete: async (email: string) => {
      if (canTrack && !isInternalDomain(email) && !isInternalDomain(email)) {
        await mixpanel.people?.delete_user();
      }
    },

    setProp: async (key: string, value: PropsType, email: string) => {
      if (canTrack && !isInternalDomain(email)) await mixpanel.people?.set(key, value);
    },
  },
};

export default Mixpanel;
