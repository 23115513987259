import has from 'lodash/has';

type CognitoErrorsType = {
  [key: string]: string;
};

const COGNITO_ERRORS: CognitoErrorsType = {
  CodeMismatchException: "The code you've entered does not match, please try again",
  EmptyCode: 'Please enter your code',
  EmptyPassword: 'Please enter your password',
  EmptyUsername: 'Please enter your email',
  ExpiredCodeException: "The code you've entered has expired, please request a new code",
  InvalidPasswordException: 'The email or password wasn’t entered correctly. Please check and try again',
  InvalidUsername: 'This is not a valid email, please check and try again',
  NotAuthorizedException: 'The email or password wasn’t entered correctly. Please check and try again',
  NoUserSession: 'You session has expired, please sign in again',
  SignUpError: 'There was a problem signing you up, please try again',
  TooManyFailedAttemptsException:
    "Please slow down! You're attempting this action too frequently, please wait before trying again",
  UnauthorizedException: 'The email or password wasn’t entered correctly. Please check and try again',
  UserNotFoundException: 'The email or password wasn’t entered correctly. Please check and try again',
  UsernameExistsException: 'This email has already been used to sign up, please sign in',
  UserNotConfirmedException: 'You need to verify your account before continuing',
  _default: 'Something went wrong, please try again',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getAuthErrorMessage = (error: any) => {
  const stringError = String(error.code);

  return has(COGNITO_ERRORS, stringError) ? COGNITO_ERRORS[stringError] : COGNITO_ERRORS._default;
};

export default getAuthErrorMessage;
