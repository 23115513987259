import { INTERNAL_USERS } from '../../flags/internal-users';
import { INTERNAL_DOMAINS } from '../../flags/internal-domains';

export const isInternalUser = (email: string) => {
  if (!email || typeof email !== 'string') return false;

  return INTERNAL_USERS.includes(email);
};

export const isInternalDomain = (email?: string) => {
  if (!email || typeof email !== 'string') return false;

  const domain = email.substring(email.indexOf('@') + 1);

  return INTERNAL_DOMAINS.includes(domain);
};
