import ReactGA from 'react-ga4';

ReactGA.initialize(process.env.GOOGLE_ANALYTICS_MEASUREMENT_ID);

const GA = {
  event: (category: string, action: string, label = '') => {
    return ReactGA.event({
      category: category,
      action: action,
      label: label,
    })
  }
};

export default GA;

