export const INTERNAL_USERS = [
  'george@hundo.xyz',
  'george+visible@hundo.xyz',
  'giacomo@hundo.xyz',
  'liam.morgan+new@play-studio.com',
  'steve@beard.wtf',
  'will.a.griffiths@gmail.com',
  'will.griffiths@play-studio.com',
  'scott@hundo.xyz',
  'mike.hamilton@hundo.xyz',
  'humma.nazir@play-studio.com',
  'gabriella.auguszt@play-studio.com',
  'alessio.pece@play-studio.com',
  'alastair.mcfarlane@play-studio.com',
  'amelia@hundo.xyz'
];