import employersLogo from '/public/icons/employers.svg';
import careerconLogo from '/public/icons/careercon-tent.svg';
import coursesLogo from '/public/icons/courses.svg';
import eventsLogo from '/public/icons/events.svg';
import profileLogo from '/public/icons/profile.svg';
import helpLogo from '/public/icons/help.svg';
import { SocialArrayItem } from '../types';

export const PAGES = [
  {
    NAME: 'CAREERCON22',
    ROUTE: '/careercon',
    BACKGROUND_COLOR: '#55DAFC',
    ICON: careerconLogo,
  },
  {
    NAME: 'LEARN',
    ROUTE: '/learn',
    BACKGROUND_COLOR: '#BE88F2',
    ICON: coursesLogo,
  },
  {
    NAME: 'EMPLOYERS',
    ROUTE: '/employers',
    BACKGROUND_COLOR: '#10DFC7',
    ICON: employersLogo,
  },
  {
    NAME: 'EVENTS',
    ROUTE: '/events',
    BACKGROUND_COLOR: '#F877E0',
    ICON: eventsLogo,
  },
  {
    NAME: 'PROFILE',
    ROUTE: '/profile',
    BACKGROUND_COLOR: '#FFFF50',
    ICON: profileLogo,
  },
  {
    NAME: 'HELP',
    ROUTE: 'https://hundo.xyz/contact/help',
    BACKGROUND_COLOR: '#FFFFFF',
    ICON: helpLogo,
  },
];

export type RegexPatternsType = {
  NAME: RegExp;
  EMAIL: RegExp;
  INSTAGRAM: RegExp;
  TIKTOK: RegExp;
  TWITTER: RegExp;
  DISCORD: RegExp;
  URL: RegExp;
};

export const REGEX: RegexPatternsType = {
  NAME: /^[\p{L}\p{M}\p{Zs}-]+$/u,
  EMAIL:
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  INSTAGRAM: /^[a-zA-Z0-9_.]*$/,
  TIKTOK: /^[a-zA-Z0-9_.]*$/,
  TWITTER: /^[a-zA-Z0-9_.]*$/,
  DISCORD: /^.{3,32}#\d{4}$/,
  URL: /^(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?$/,
};

export const PASSWORD_RULES = [
  {
    RULE: /^[A-Z0-9a-z/~!@#$%^&*_+\-=\\`|\\(){}[\]:;"'<>,.?=+`]{8,}$/,
    TEXT: 'Minimum 8 characters (do not include space characters)',
  },
  {
    RULE: /[A-Z]/,
    TEXT: 'Uppercase characters (A-Z)',
  },
  {
    RULE: /[a-z]/,
    TEXT: 'Lowercase characters (a-z)',
  },
  {
    RULE: /[0-9]/,
    TEXT: 'Digits (0-9)',
  },
  {
    RULE: /[/~!@#$%^&*_+\-=\\`|\\(){}[\]:;"'<>,.?=+`]/,
    TEXT: `Special characters (~!@#$%^&*_-+=\`|\\(){}[]:;"'<>,.?/)`,
  },
];
export const CONFETTI_COLORS = [
  '#42f542', // green
  '#7dfbff', // sky blue
  '#ff3c36', // red
  '#ff9645', // orange
  '#fff645', // yellow
  '#5145ff', // blue
  '#ff45da', // pink
];

export const INTERESTS = [
  'Gaming',
  'Art / Drawing / Painting',
  'Design',
  'Fashion',
  'Music Production',
  'Playing an Instrument',
  'Photography',
  'Video Production',
  'Live Streaming',
  'Coding',
  'Writing',
  'Reading',
  'Crafting',
  'Sports',
  'Fitness',
  'Mindfulness',
  'Environmentalism',
  'Podcasts',
  'Cooking / Baking',
  'Singing',
  'Dancing',
  'Swimming',
  'Traveling',
  'Walking / Hiking',
  'Online Shopping',
  'Food',
];

export const SOCIALS_ARRAY: SocialArrayItem[] = [
  {
    title: 'tikTok',
    tooltip: 'Example: <b>myprofilename</b>',
  },
  {
    title: 'instagram',
    tooltip: 'Example: <b>myprofilename</b>',
  },
  {
    title: 'twitter',
    tooltip: 'Example: <b>myprofilename</b>',
  },
  {
    title: 'discord',
    tooltip: 'Example: <b>myprofilename#1234</b>',
  },
];
